import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import LandingParticles from './LandingParticle';
// import LandingHex from './LandingHex';
import Button from '../../Button';
import { bannerHeaderVar } from '../../../utils/framerVariants';

const LandingHero = ( { headings } ) => {
	const [ index, setIndex ] = useState( 0 );

	/* const [ color, setColor ] = useState( '#0187e1' );
	   const [ rotate, setRotate ] = useState( 0 ); */

	useEffect( () => {
	
		const interval = setInterval( () => {
			setIndex( prev => prev < headings.length - 1 ? prev + 1 : 0 );
		}, 5000 );

		return () => {
			clearInterval( interval );
		};
	}, [ headings.length, setIndex ] );

	/* useEffect( () => {
	   	const selectColor = () => {
	   		if ( rotate === 3 ) {
	   			setColor( '#0b4a9a' );
	   		} else if ( rotate % 2 ) {
	   			setColor( '#2796c9' );
	   		} else {
	   			setColor( '#0187e1' );
	   		}
	   	}; */

	/* 	return () => {
	   		selectColor();
	   	};
	   }, [ rotate ] ); */

	return (
		<div className="landingHeroWrapper">

			<LandingParticles/>

			<div
				className="landingHero">

				<div>
					<motion.div
						key={ index }
						variants={ bannerHeaderVar }
						initial="hidden"
						animate="visible"
						exit="hidden"
					>
						<h1 style={{ color: '#0187e1' }}>{ headings[ index ].heading }</h1>
						<h4>{ headings[ index ].subHeading}</h4>
					</motion.div>
					<div className="buttonGroup">
						<a href="#landing-content">
							<Button primary buttonText="Learn more"/>
						</a>
						<a href="#form">
							<Button outline buttonText="Contact sales"/>
						</a>
					</div>
				</div>
			</div>
			<div className="down_arrow"><i className="fas fa-arrow-alt-circle-down fa-bounce"></i></div>
		</div>
	);
};

LandingHero.propTypes = { headings: PropTypes.array };

export default LandingHero;
