import React from "react";
import Particles from "react-tsparticles";


export default function App() {
  return (
    <Particles
      options={ {
        "fullScreen": {
            "enable": false,
            "zIndex": 1
        },
        "fpsLimit": 120,
        "interactivity": {
            "events": {
                "onClick": {
                    "enable": true,
                    "mode": "push"
                },
                "onHover": {
                    "enable": false,
                    "mode": "bubble",
                    "parallax": {
                        "enable": false,
                        "force": 60,
                        "smooth": 10
                    }
                },
                "resize": true
            },
            "modes": {
                "bubble": {
                    "distance": 400,
                    "duration": 2,
                    "opacity": 1,
                    "size": 40,
                    "speed": 3
                },
                "grab": {
                    "distance": 400,
                    "lineLinked": {
                        "opacity": 1
                    }
                },
                "push": {
                    "quantity": 4
                },
                "remove": {
                    "quantity": 2
                },
                "repulse": {
                    "distance": 200,
                    "duration": 0.4
                }
            }
        },
        "particles": {
            "color": {
                "value": ["#084391","#0187e1","#61B0B7"]
              },
            "lineLinked": {
                "color": "#5bc0eb",
                "distance": 150,
                "enable": true,
                "opacity": 0.4,
                "width": 1
            },
            "move": {
                "attract": {
                    "enable": false,
                    "rotateX": 600,
                    "rotateY": 1200
                },
                "bounce": false,
                "direction": "none",
                "enable": true,
                "out_mode": "bounce",
                "random": false,
                "speed": 1,
                "straight": false
            },
            "number": {
                "density": {
                    "enable": true,
                    "value_area": 900
                },
                "value": 100
            },
            "opacity": {
                "animation": {
                    "enable": true,
                    "minimumValue": 0.1,
                    "speed": 1,
                    "sync": false
                },
                "random": false,
                "value": .3
            },
            "shape": {
                "character": {
                    "fill": false,
                    "font": "Verdana",
                    "style": "",
                    "value": "*",
                    "weight": "400"
                },
                "polygon": {
                    "nb_sides": 6
                },
                "stroke": {
                    "color": "#000000",
                    "width": 0
                },
                "type": "polygon"
            },
            "size": {
                "animation": {
                    "enable": false,
                    "minimumValue": 0.1,
                    "speed": 40,
                    "sync": false
                },
                "random": true,
                "value": 16
            }
        },
        "polygon": {
            "draw": {
                "enable": false,
                "lineColor": "#ffffff",
                "lineWidth": 0.5
            },
            "move": {
                "radius": 10
            },
            "scale": 1,
            "type": "none",
            "url": ""
        },
        "retina_detect": true,
        "background": {
            "color": "transparent",
            "image": "",
            "position": "50% 50%",
            "repeat": "no-repeat",
            "size": "cover"
        }
    }}
    />
  );
}
